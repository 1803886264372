import React from 'react'
import styled from 'styled-components'

// Styles
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'
import colors from '../styles/colors'

// Components
import SectionHeader from './SectionHeader'
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'
import { Col as ColComponent } from './layout/Grid'

// Types
import { IProps as ISectionHeader } from './SectionHeader'
interface ISection {
  sectionHeader: ISectionHeader
  richText: string
}

interface IProps {
  data: ISection
}

const Section = ({ data }: IProps) => (
  <>
    <HeaderWrapper>
      <AnimatedScrollWrapper>
        <SectionHeader {...data.sectionHeader} />
      </AnimatedScrollWrapper>
    </HeaderWrapper>
    <Col mOffset={2} m={12} lOffset={3} l={10}>
      <AnimatedScrollWrapper>
        <RichText dangerouslySetInnerHTML={{ __html: data.richText }} />
      </AnimatedScrollWrapper>
    </Col>
  </>
)

const HeaderWrapper = styled.div`
  margin-bottom: 48px;

  ${mq.from.M`
    margin-bottom: 80px;
  `}

  ${mq.from.L`
    margin-bottom: 100px;
  `}

  ${mq.from.XXL`
    margin-bottom: 150px;
  `}
`

const Col = styled(ColComponent)`
  padding: 0 20px;

  ${mq.from.M`
    padding: 0;
  `}
`

const RichText = styled.div`
  > h3 {
    ${textStyles.headingSRegular}
    margin-bottom: 16px;
  }

  > p {
    ${textStyles.body}
    margin-bottom: 24px;
  }

  > ul {
    ${textStyles.body}
    list-style-type: square;
  }

  a {
    ${textStyles.hyperlink}
    color: ${colors.white};
    text-decoration: underline;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
    > h3 {
      margin-bottom: 24px;
    }

    > p {
      margin-bottom: 32px;
    }
  `}

  ${mq.from.XL`
    > h3 {
      margin-bottom: 32px;
    }

    > p {
      margin-bottom: 40px;
    }
  `}

  ${mq.from.XXL`
    > h3 {
      margin-bottom: 48px;
    }

    > p {
      margin-bottom: 80px;
    }
  `}
`

export default Section
