import React from 'react'
import styled from 'styled-components'

interface IProps {
  image: any
  className?: string
}

const Image = ({ image, className }: IProps) => (
  <Container image={image} className={className} />
)

const Container = styled.div<{ image: any }>`
  padding-top: 100%;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default Image
