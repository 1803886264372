import React from 'react'
import styled, { css } from 'styled-components'

// Styles
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'

// Components
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'
import { Col } from './layout/Grid'
import Image from './Image'

import ctaBackground from '../assets/ctaBackground.svg'

/**
 * @TODO
 * Move content to ../content/index.ts
 */
const CTA = () => {
  return (
    <AnimatedScrollWrapper>
      <Wrapper>
        <Background image={ctaBackground} />
        <TextCol mOffset={2} m={6}>
          <Title>Happy 2021</Title>
          <Text>Want to discuss future possibilities?</Text>
          <TryUs href={`https://www.jungleminds.com/try-us`}>Try Us</TryUs>
        </TextCol>
      </Wrapper>
    </AnimatedScrollWrapper>
  )
}

const Wrapper = styled.address`
  position: relative;
  background-color: ${colors.accent2};
  color: ${colors.black};
  margin: 0;
  padding: 100px 20px 91px;
  font-style: normal;

  ${mq.from.M`
    padding: 120px 0;
  `}

  ${mq.from.L`
    padding: 186px 0;
  `}

  ${mq.from.XL`
    padding: 209px 0;
  `}

  ${mq.from.XXL`
    padding: 298px 0;
  `}
`

const Background = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  padding-top: 0;

  ${mq.from.M`
    width: 384px;
  `}

  ${mq.from.L`
    width: 512px;
  `}

  ${mq.from.XL`
    width: 640px;
  `}

  ${mq.from.XXL`
    width: 960px;
  `}
`

const TextCol = styled(Col)`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  z-index: 2;
`

const Title = styled.h2`
  ${textStyles.headingL}
  margin-bottom: 24px;

  ${mq.from.M`
    margin-bottom: 40px;
  `}

  ${mq.from.XXL`
    margin-bottom: 80px;
  `}
`

const Text = styled.p`
  ${textStyles.contact}
  margin-bottom: 8px;

  ${mq.from.M`
    margin-bottom: 16px;
  `}

  ${mq.from.XL`
    margin-bottom: 24px;
  `}
`

const sharedLinkStyles = css`
  ${textStyles.hyperlink}
  ${textStyles.contact}
`

const TryUs = styled.a`
  ${sharedLinkStyles}
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;

  ${mq.from.L`
    font-size: 32px;
  `}

  ${mq.from.XL`
    font-size: 48px;
  `}
`

export default CTA
