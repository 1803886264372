import React from 'react'
import styled from 'styled-components'

// Styles
import { mq } from '../styles/utils/mq'
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { duration, ease } from '../styles/animations'

// Types
interface INavItem {
  label: string
}

interface IProps {
  items: INavItem[]
  activeLabel: string
}

const InPageNav = ({ items, activeLabel }: IProps) => {
  const handleClick = (id: string) => {
    const el = document.getElementById(id)
    el?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Container>
      {items.map((item, i) => (
        <NavItem key={`inPageNavItem-${i}`}>
          <Dot
            isActive={item.label === activeLabel}
            onClick={() => handleClick(item.label)}
          />
          <Label>{`${i === 0 ? '' : `#${i}`} ${item.label}`}</Label>
        </NavItem>
      ))}
    </Container>
  )
}

const Container = styled.nav`
  display: none;

  ${mq.from.L`
    position: fixed;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    display: block;
    z-index: 10;
  `}

  ${mq.from.XL`
    right: 24px;
  `}

  ${mq.from.XXL`
    right: 1.6666vw;
  `}
`

const NavItem = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled.span`
  ${textStyles.inPageNav}
  margin-right: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity ${duration.medium}ms ${ease.cubic};

  ${mq.from.XXL`
    margin-right: 8px;
  `}
`

const Dot = styled.div<{ isActive: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1.17px solid ${colors.white};
  cursor: pointer;
  ${({ isActive }) => isActive && `background: ${colors.white};`}
  transition: background ${duration.medium}ms ${ease.cubic};

  &:hover {
    ~ ${Label} {
      opacity: 1;
    }
  }

  ${mq.from.XL`
    width: 16px;
    height: 16px;
    border-width: 1.34px
  `}

  ${mq.from.XXL`
    width: 24px;
    height: 24px;
    border-width: 2px
  `}
`

export default InPageNav
