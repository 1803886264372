import React from 'react'
import styled, { css } from 'styled-components'

// Styles
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'

// Component
import AnimatedTextScrollWrapper from './animation/AnimatedTextScrollWrapper'
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'
import { Col } from './layout/Grid'
import Image from './Image'

// Types
export interface IProps {
  rank: number
  title: string
  illustration: any
}

const SectionHeader = ({ rank, title, illustration }: IProps) => {
  const rankString = `0${rank}`

  return (
    <Wrapper>
      <Col xxsOffset={1} xxs={6} mOffset={2} m={8} lOffset={2} l={8}>
        <Rank rank={rankString}>{rankString}</Rank>
        <Title>
          <AnimatedTextScrollWrapper
            text={[title]}
            textStyles={textStyles.headingL}
          />
        </Title>
      </Col>
      <Col xxsOffset={4} xxs={4} mOffset={0} m={6}>
        <AnimatedScrollWrapper>
          <Illustration image={illustration} />
        </AnimatedScrollWrapper>
      </Col>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding-top: 82px;

  ${mq.from.M`
    padding-top: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  `}
`

const sharedRankStyle = css<{ rank: string }>`
  ${textStyles.rank}
  line-height: 0.90;
  content: '${({ rank }) => rank}';
  display: block;
  background-color: ${colors.white};
  position: absolute;
  left: 0;
`

const Rank = styled.p<{ rank: string }>`
  ${textStyles.rank}
  margin-bottom: 8px;
  color: ${colors.accent2};
  background-color: ${colors.white};
  position: relative;

  &::before {
    ${sharedRankStyle}
    top: -20%;
  }

  &::after {
    ${sharedRankStyle}
    top: -50%;
  }

  ${mq.from.M`
    margin-top: 0;
    margin-bottom: 28px;

    &::before {
      top: -10px;
    }

    &::after {
      top: -27px;
    }
  `}

  ${mq.from.XL`
    margin-bottom: 34px;

    &::before {
      top: -14px;
    }

    &::after {
      top: -38px;
    }
  `}

  ${mq.from.XXL`
    margin-bottom: 48px;

    &::before {
      top: -22px;
    }

    &::after {
      top: -60px;
    }
  `}
`

const Title = styled.div`
  color: ${colors.black};
`

const Illustration = styled(Image)`
  background-size: contain;
  padding-top: 138.889%;
`

export default SectionHeader
