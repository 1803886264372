import React from 'react'

// Styles
import colors from '../../styles/colors'

// Types
interface IProps {
  width?: number
  height?: number
  color?: string
  className?: string
}

const Download = ({
  className,
  width,
  height,
  color = colors.black,
}: IProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 29 26"
    fill="none"
  >
    <path fill={color} d="M13.6.8h1v18.63h-1zM0 17h1v8H0zM27.2 17h1v8h-1z" />
    <path fill={color} d="M0 24.06h28v1H0z" />
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3 10.783l-8.976 9.2-.002-.003v.003l-.221.225-.225-.225-8.976-9.2.56-.575 8.64 8.854 8.639-8.854.56.575z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="rotate(90 7.045 16.255)"
          d="M0 0h10.53v18.4H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Download
