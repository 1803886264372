import React from 'react'
import styled from 'styled-components'

// Styles
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'

// Components
import DownloadIconComponent from './icons/Download'
import AnimatedScrollWrapperComponent from './animation/AnimatedScrollWrapper'

// Types
interface IProps {
  url: string
  label: string
  target?: string
  className?: string
}

const DownloadButton = ({ url, label, target, className }: IProps) => {
  return (
    <AnimatedScrollWrapper>
      <Wrapper className={className} href={url} target={target}>
        <Label>{label}</Label>
        <DownloadIcon />
      </Wrapper>
    </AnimatedScrollWrapper>
  )
}

const AnimatedScrollWrapper = styled(AnimatedScrollWrapperComponent)`
  height: 100%;
`

const Label = styled.p`
  ${textStyles.hyperlink}
  ${textStyles.downloadButton}
  color: ${colors.black};
  margin-bottom: 10px;
  text-align: center;
`

const DownloadIcon = styled(DownloadIconComponent)`
  width: 28px;
  height: 24px;

  ${mq.from.L`
    width: 35px;
    height: 30px;
  `}

  ${mq.from.XXL`
    width: 1.82vw;
    height: 1.82vw;
  `}
`

const Wrapper = styled.a`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: ${colors.accent2};
  border-radius: 50%;
  text-decoration: none;

  &:hover {
    ${Label} {
      text-decoration: underline;
    }
  }
`

export default DownloadButton
