import React from 'react'
import styled from 'styled-components'

// Styles
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'

// Types
interface IProps {
  title: string
  text: string
  className?: string
}

const InfoBlock = ({ title, text, className }: IProps) => {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${colors.accent2};
  border-left: 1px solid ${colors.accent2};
  padding-left: 4px;

  ${mq.from.M`
    padding-left: 8px;
  `}
`

const Title = styled.h3`
  ${textStyles.headingSBold}
  margin-bottom: 8px;

  ${mq.from.M`
    margin-bottom: 16px;
  `}

  ${mq.from.XL`
    margin-bottom: 24px;
  `}

  ${mq.from.XXL`
    margin-bottom: 42px;
  `}
`

const Text = styled.p`
  ${textStyles.body}
  margin-bottom: 0;
`

export default InfoBlock
