import React from 'react'
import styled from 'styled-components'

// Styles
import textStyles from '../styles/textStyles'
import colors from '../styles/colors'
import { mq } from '../styles/utils/mq'

// Components
import Image from './Image'
import { Col as ColComponent, Row } from './layout/Grid'
import Year from '../assets/Year'
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'
import AnimatedTextScrollWrapper from './animation/AnimatedTextScrollWrapper'

// Image
import headerBackground from '../assets/headerBackground.png'

/**
 * @TODO
 * Move content to ../content/index.ts
 */

const Header = () => (
  <header>
    <BackgroundImage image={headerBackground} />
    <Wrapper>
      <AnimatedScrollWrapper>
        <Col>
          <Year />
        </Col>
      </AnimatedScrollWrapper>
      <Col mOffset={1} m={14} xxlOffset={1} xxl={10}>
        <Title>
          <AnimatedTextScrollWrapper
            text={['The big acceleration']}
            textStyles={textStyles.headerTitle}
            delay={1}
          />
        </Title>
      </Col>
    </Wrapper>
    <Col
      mOffset={2}
      m={12}
      lOffset={3}
      l={10}
      xxsOffset={1}
      xxs={6}
      xxlOffset={3}
      xxl={10}
    >
      <AnimatedTextScrollWrapper
        text={[
          '7 wild predictions about how Digital is going to shape our lives in the new year',
        ]}
        textStyles={textStyles.headerHeading}
        delay={1.2}
      />
    </Col>
  </header>
)

// Main styles
const Wrapper = styled(Row)`
  padding-top: 240px;

  ${mq.from.M`
    padding-top: 190px;
  `}

  ${mq.from.L`
    padding-top: 220px;
  `}

  ${mq.from.XL`
    padding-top: 300px;
  `}

  ${mq.from.XXL`
    padding-top: 15.625vw;
  `}
`

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 120%;
  z-index: 0;

  ${mq.from.M`
    padding-top: 90%;
  `}

  ${mq.from.L`
    padding-top: 77%;
  `}

  ${mq.from.XL`
    padding-top: 72%;
  `}
`

const Col = styled(ColComponent)`
  position: relative;
  z-index: 1;
`

const Title = styled.div`
  color: ${colors.accent1};
  margin: -33px 20px 48px;

  ${mq.from.M`
    margin: -56px 0 48px;
  `}

  ${mq.from.L`
    margin-top: -67px;
  `}

  ${mq.from.XL`
    margin-bottom: 96px;
  `}

  ${mq.from.XXL`
    margin-top: -24px;
    margin-bottom: 80px;
    transform: translateY(-50%);
  `}
`

export default Header
