import React from 'react'
import styled from 'styled-components'

// Styles
import { Col, Row as RowComponent } from './layout/Grid'
import textStyles from '../styles/textStyles'
import { mq } from '../styles/utils/mq'

// Components
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'
import InfoBlockComponent from './InfoBlock'

/**
 * @TODO
 * Move content to ../content/index.ts
 */

const Introduction = () => {
  return (
    <>
      <Row isLabel>
        <AnimatedScrollWrapper>
          <Col xxs={3} mOffset={2} m={5} lOffset={3} l={4}>
            <Label>Reading time — 10 minutes</Label>
          </Col>
        </AnimatedScrollWrapper>
      </Row>
      <Row>
        <AnimatedScrollWrapper>
          <Col mOffset={2} m={12} lOffset={3} l={10}>
            <Heading>
              Imagine walking into a time machine somewhere late 2019 and
              casually travelling a year forward in time. Chances are the scenes
              would feel slightly apocalyptic. Everyone is wearing facemasks,
              awkward elbow bumps have become the norm and all other social
              contact takes place through Zoom, an app which you hadn’t even
              heard of 12 months ago (while you are here in the past, it may be
              a good idea to go and buy some shares). Face masks and new social
              rituals aside, zooming out (no pun intended): the past year has
              put into action some pretty spectacular changes:
            </Heading>
          </Col>
        </AnimatedScrollWrapper>
      </Row>
      <Row noLeftPadding>
        <AnimatedScrollWrapper>
          <Col xxsOffset={1} xxs={7} mOffset={1} m={14}>
            <InfoBlockWrapper>
              <InfoBlock
                title="E-commerce has taken a massive leap"
                text="In the US, e-commerce penetration moved from 17% to a staggering 33% in just two months (in a previous estimate, the e-commerce penetration wasn’t predicted to reach 24% until 2024!)."
              />
              <InfoBlock
                title="Digital investments are a top priority"
                text="During the first months of COVID-19 while most corporate funding was dramatically decreased, funding for digital initiatives increased more than anything else. This included more than just profits, but also the number of people in technology roles and the number of customers."
              />
              <InfoBlock
                title="In short"
                text="2020 has pushed both consumers and companies over a tech tipping point affecting how companies interact with customers as well as how companies work internally. The urgency to deliver digital business value has been put at the front and centre of each post-COVID-19 strategy. So we ask ourselves the daunting question: "
              />
            </InfoBlockWrapper>
          </Col>
        </AnimatedScrollWrapper>
      </Row>
      <Row>
        <AnimatedScrollWrapper>
          <Col mOffset={2} m={12} lOffset={3} l={10}>
            <Title>Where do we go from here?</Title>
            <Paragraph>
              One thing the pandemic has made clear is that people and human
              interaction are still at the centre of all businesses. People need
              to connect, collaborate and interact with one another. The past
              year has proved that we as a humanity have a versatile and
              adaptable nature. Answering to an urgent need for new digitised
              processes, we were able to reinvent the way we work, collaborate
              and interact within organisations. And with that, another question
              arises:
            </Paragraph>
          </Col>
        </AnimatedScrollWrapper>
      </Row>
      <Row>
        <AnimatedScrollWrapper>
          <Col mOffset={2} m={12} lOffset={3} l={10}>
            <Title bigger>Is 2021 The End of ‘Digital’?</Title>
            <Paragraph>
              Digital has become such an integral part of our lives that it
              seems the word ‘digital’ is rapidly losing its meaning. After all,
              in order to label certain parts of life as ‘Digital’, other parts
              need to be labelled as ‘non-digital’. This distinction has been
              made pretty much obsolete, merging whatever we used to consider
              ‘Digital’ or ‘non-digital’ and creating new horizons for the ways
              we build our daily interactions, collaborate and do business.
            </Paragraph>
            <Paragraph>
              <strong>
                So what type of opportunities does this specific point in time
                present us with?
              </strong>{' '}
              Clearly, we do not know - luckily that has never stopped us from
              making predictions :)
            </Paragraph>
          </Col>
        </AnimatedScrollWrapper>
      </Row>
    </>
  )
}

const Row = styled(RowComponent)<{
  isLabel?: boolean
  noLeftPadding?: boolean
}>`
  margin-bottom: ${({ isLabel }) => (isLabel ? '90px' : '48px')};
  padding: ${({ noLeftPadding }) => (noLeftPadding ? '0 20px 0 0' : '0 20px')};

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
    margin-bottom: ${({ isLabel }: { isLabel?: boolean }) =>
      isLabel ? '48px' : '80px'};
    padding: 0;
  `}

  ${mq.from.L`
    margin-bottom: ${({ isLabel }: { isLabel?: boolean }) =>
      isLabel ? '40px' : '100px'};
  `}

  ${mq.from.XL`
    margin-bottom: ${({ isLabel }: { isLabel?: boolean }) =>
      isLabel ? '50px' : '100px'};
  `}

  ${mq.from.XXL`
    margin-bottom: ${({ isLabel }: { isLabel?: boolean }) =>
      isLabel ? '80px' : '150px'};
  `}
`

const Label = styled.span`
  ${textStyles.bodyLabel}
`

const Title = styled.h3<{ bigger?: boolean }>`
  ${({ bigger }) => (bigger ? textStyles.headingM : textStyles.headingSBold)}
  margin-bottom: 16px;

  ${mq.from.M`
    margin-bottom: 24px;
  `}

  ${mq.from.XL`
    margin-bottom: 32px;
  `}

  ${mq.from.XXL`
    margin-bottom: 80px;
  `}
`

const Heading = styled.p`
  ${textStyles.introHeading};
  margin: 0;
`

const Paragraph = styled.p`
  ${textStyles.body}
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const InfoBlockWrapper = styled.div`
  ${mq.from.M`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  `}
`

const InfoBlock = styled(InfoBlockComponent)`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
    margin-bottom: 0;
    margin-right: 6.25vw;
    flex: 1 0 0px;

    &:last-child {
      margin-right: 0;
    }
  `}
`

export default Introduction
