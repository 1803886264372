import React from 'react'

// Types
interface IProps {
  className?: string
}

const Year = ({ className }: IProps) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 643"
  >
    <path
      d="M-15.247 628h469.472V510.632H172.887v-2.589c48.328-59.547 141.532-98.382 205.394-156.203 44.876-37.972 76.807-84.574 76.807-155.34C455.088 86.899 375.692.599 232.434.599c-94.93 0-161.381 33.657-196.764 85.437-28.479 39.698-41.424 85.437-41.424 146.71h133.765c0-37.109 8.63-68.177 28.479-89.752 16.397-19.849 39.698-28.479 71.629-28.479 53.506 0 84.574 34.52 84.574 86.3 0 43.15-23.301 70.766-62.999 99.245-62.136 44.876-143.258 94.067-200.216 157.066C8.917 502.865-14.384 552.056-15.247 628zm800.429 14.671c162.244 0 268.398-125.135 268.398-321.036C1053.58 124.871 948.289.599 786.045.599c-158.792 0-267.53 125.135-267.53 321.036s108.738 321.036 266.667 321.036zm.863-111.327c-86.3 0-122.546-84.574-122.546-209.709 0-125.998 36.246-211.435 122.546-211.435 87.163 0 122.546 85.437 122.546 211.435s-34.52 209.709-122.546 209.709zM1101.8 628h469.47V510.632h-281.34v-2.589c48.33-59.547 141.53-98.382 205.4-156.203 44.87-37.972 76.8-84.574 76.8-155.34 0-109.601-79.39-195.901-222.65-195.901-94.93 0-161.38 33.657-196.76 85.437-28.48 39.698-41.43 85.437-41.43 146.71h133.77c0-37.109 8.63-68.177 28.48-89.752 16.39-19.849 39.69-28.479 71.62-28.479 53.51 0 84.58 34.52 84.58 86.3 0 43.15-23.3 70.766-63 99.245-62.14 44.876-143.26 94.067-200.22 157.066-40.56 45.739-63.86 94.93-64.72 170.874zm676.92 0h140.67V7.503h-111.33c-14.67 84.574-81.98 122.546-171.74 125.135v94.067h142.4V628z"
      fill="#fff"
    />
  </svg>
)

export default Year
